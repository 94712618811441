import React, {useState, useEffect, useContext, useRef} from 'react';
import axios from '../utils/service';
import Spinner from './Spinner';
import styles from './LessonsPage.module.css';

import AuthContext from "../context/AuthContext";
import {useNavigate, useParams} from "react-router-dom"; // Reuse the same styles

function CategoriesPage() {
  const { lessonId } = useParams();
  const [categories, setCategories] = useState({});
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileContent, setFileContent] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [previousFile, setPreviousFile] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const dividerRef = useRef(null);
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };
  useEffect(() => {
    const fetchCategories = async () => {
        const response = await axios.get(`/lessons/categories`, config);
        setCategories(response.data);
    }
    fetchCategories();
  }, []);

  useEffect(() => {
    if(lessonId) {
      handleFileSelect(lessonId);
    }
  }, [lessonId]);

  const adjustImagePaths = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    doc.querySelectorAll('li').forEach(li => {
      li.style.listStyleType = 'disc';
      const checkbox = li.querySelector('input[type="checkbox"]');
      if (checkbox) checkbox.remove();
    });
    return doc.body.innerHTML;
  };


    const handleFileSelect = (file) => {
    setPreviousFile(selectedFile);
    setSelectedFile(file);
    setSearchResults(null);
    setSearchQuery("");
    setLoading(true);


    const encodedLessonName = encodeURIComponent(file.replace('.html', ''));
    navigate(`/lessons/${encodedLessonName}`);

    axios.get(`/lessons/${file}`, config)
      .then(response => {
        const styleTag = `
          <style>
            .readerContent h1.noteTitle { font-family: 'Source Sans Pro', sans-serif; font-size: 32px; margin: 21px 0; }
            .readerContent .para { font-size: 16pt; line-height: 1.5; color: #333; }
            .readerContent table { width: 100%; border-collapse: collapse; margin: 20px 0; }
            .readerContent img { max-width: 100%; height: auto; }
            a[rel=noopener] { z-index: 101; position: relative; }
          </style>`;
        const adjustedContent = adjustImagePaths(response.data);
        setFileContent(styleTag + adjustedContent);
        setLoading(false);
        // setFileContent(styleTag + response.data);
      })
      .catch(error => {
        setLoading(false);
        console.error('Error fetching file content:', error);
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true
    axios.get(`/lessons/search?query=${searchQuery}`, config)
      .then(response => {
        setSearchResults(response.data);
        setPreviousFile(selectedFile);
        setSelectedFile(null);
      })
      .catch(error => console.error('Error searching:', error))
      .finally(() => setLoading(false)); // Set loading to false after search completes
  };

  const handleGoBack = () => {
    if (previousFile) {
      handleFileSelect(previousFile);
    }
  };

  const toggleCategory = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const [isResizing, setIsResizing] = useState(false);
  const [column1Width, setColumn1Width] = useState('120px');

  const handleMouseDown = (e) => {
    e.preventDefault();
    if(!isResizing) {
      setIsResizing(true);
    }
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      const container = document.getElementById(styles.mainContent);
      const containerOffsetLeft = container.offsetLeft;
      const containerWidth = container.offsetWidth;
      const mouseX = e.clientX - containerOffsetLeft;

      const newColumn1Width = (mouseX / containerWidth) * 100;
      if (newColumn1Width > 10 && newColumn1Width < 90) {
        setColumn1Width(`${newColumn1Width}%`);
      }
    }
  };

  const handleMouseUp = (e) => {
    if(isResizing) {
      setIsResizing(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  useEffect(() => {
    document.addEventListener('keydown', function(event) {
      if ((event.metaKey ||event.ctrlKey) && event.key === 'a') {
        event.preventDefault();
      }else if ((event.metaKey ||event.ctrlKey) && event.key === 'c') {
        event.preventDefault();
      }else if(event.key === 'Snapshot' || event.key === 'PrintScreen') {
        event.preventDefault();
      }
    });
  })

  return (
    <div className={styles.lessonsPage}>
      <div className={styles.mainContent} id={styles.mainContent}>
        <div className={styles.sidebar}  style={{ width: column1Width }}>
          <form onSubmit={handleSearch} className={styles.searchForm}>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search lessons..."
              className={styles.searchInput}
            />
            <button type="submit" className={styles.searchButton}>Search</button>
          </form>
          <h2>Categories</h2>
          {Object.keys(categories).map(category => (
            <div key={category} className={styles.category}>
              <div className={styles.categoryHeader} onClick={() => toggleCategory(category)}>
                <h3><span className={styles.arrow}>{expandedCategory === category ? '▲' : '▼'}</span> {category}</h3>
              </div>
              {expandedCategory === category && (
                <ul className={styles.lessonList}>
                  {categories[category].map(file => (
                    <li key={file._id} onClick={() => handleFileSelect(file._id)}>
                      {file.title}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
        <div
            id="divider"
            ref={dividerRef}
            className={styles.scrollDivider}
            onMouseDown={handleMouseDown}
            // onMouseLeave={() => setIsResizing(false)}
        />
        <div className={styles.content} onContextMenu={handleContextMenu}>
        {loading ? (
          <Spinner />
        ) : searchResults ? (
          <div>
            <h2>Search Results</h2>
            {searchResults.length === 0 ? (
              <p>No results found.</p>
            ) : (
              searchResults.map((result, index) => (
                <div key={result._id} className={styles.searchResult}>
                  <h3>{result.title}</h3>
                  <button onClick={() => handleFileSelect(result._id)}>
                    View Lesson
                  </button>
                </div>
              ))
            )}
          </div>
        ) : selectedFile ? (
          <div style={{position: "relative"}}>
            {/*<div style={{position: "absolute", width: "100%", height: "100%", display: "block", zIndex: 100}}></div>*/}
          <div dangerouslySetInnerHTML={{ __html: fileContent }} />
          </div>
        ) : (
          <p>Select a lesson from the sidebar or search for content.</p>
        )}
      </div>
      </div>
    </div>
  );
}

export default CategoriesPage;
